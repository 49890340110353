import React from 'react'
import {
  StyledLayout,
  StyledHeader,
  StyledContent,
  StyledFooter,
  StyledSider,
  ContentLayout
} from './styled'

const PageView = ({ children }) => (
  <StyledLayout>
    <StyledSider>Sider</StyledSider>
    <ContentLayout>
      <StyledHeader>Header</StyledHeader>
      <StyledContent>{children}</StyledContent>
      <StyledFooter>Footer</StyledFooter>
    </ContentLayout>
  </StyledLayout>
)

export default PageView
