import React from 'react'
import Home from '../../pages/index'
import { Router } from "@reach/router"

const RouteController = () => (
  <Router>
    <Home path="/" />
  </Router>
)

export default RouteController