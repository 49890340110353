import React from 'react'
import styled from 'styled-components'
import { SizeMe } from 'react-sizeme'

const WidthContainer = styled.div`
  height: 100%;
  width: 100%;
`

const WidthProvider = Component => props => (
  <WidthContainer>
    <SizeMe>
      {({ size: { width } }) => <Component width={width} {...props} />}
    </SizeMe>
  </WidthContainer>
)

export default WidthProvider
