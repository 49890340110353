import React from 'react'
import { Responsive } from 'react-grid-layout'
import './styles/react-grid-layout.css'
import './styles/react-resizable.css'
import WidthProvider from '../../../containers/WidthProvider'

const ResponsiveGridLayout = WidthProvider(Responsive)

class GridView extends React.Component {
  render() {
    // layout is an array of objects, see the demo for more complete usage
    var layout = [
      { i: 'a', x: 0, y: 0, w: 1, h: 1 },
      { i: 'b', x: 0, y: 1, w: 1, h: 1 },
      { i: 'c', x: 0, y: 2, w: 1, h: 1 }
    ]
    return (
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout, md: layout, sm: layout }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      >
        <div key="a">a</div>
        <div key="b">b</div>
        <div key="c">c</div>
      </ResponsiveGridLayout>
    )
  }
}

export default GridView
