import React from 'react'
import PageView from '../components/Views/PageView'
import GridView from '../components/Views/GridView'

const Home = () => {
  return (
    <PageView>
      <GridView />
    </PageView>
  )
}

export default Home
