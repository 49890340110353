import styled from 'styled-components'
import { Layout } from 'antd'

const { Header, Footer, Sider, Content } = Layout

export const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
`

export const StyledSider = styled(Sider)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 10%;
  height: 100%;
  max-height: 100%;
`

export const StyledHeader = styled(Header)`
  height: 100px;
  width: 100%;
`

export const StyledFooter = styled(Footer)`
  height: 100px;
  width: 100%;
`

export const StyledContent = styled(Content)`
  display: flex;
  width: 100%;
  height: 100%;
`

export const ContentLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 100%;
`
